import React from "react";
import Title from "../Title/Title";
// import Cards from "./Cards/Cards";
import Agenda from "../Agenda/Agenda.jsx";

export default function Eventos(){
    return(
        <section id="Agenda" className="flex flex-col justify-center w-[100%] py-2">
            <Title link={"https://www.instagram.com/sementeaju/"}>
                Agenda
            </Title>
            <Agenda/>
        </section>
    );
}