import React from "react";
import { BsInstagram, BsYoutube, BsWhatsapp } from 'react-icons/bs';



export default function Footer(){

    var linkedin = "https://www.linkedin.com/in/jennyfer-c-santos/";
    const styleIcon = "text-colorBase hover:text-red-600 cursor-pointer mobile:text-sm";

    return(
        <footer className="flex flex-row justify-around p-2 items-center bg-slate-400 w-[100%] h-auto mobile:flex-col tablet-2:flex-col">
            
            <span className="text-[1rem] mobile:text-[14px] mobile:mt-2">©2023 Desenvolvido por 
                <a href={linkedin} className="text-slate-700 hover:text-colorBase font-style: italic font-semibold"> Jennyfer Sampaio</a>
            </span>

            <div className="flex flex-row justify-start items-center">
                <h4 className="text-gray-800 mobile:text-[14px]">Redes Sociais: </h4>
                <ul className="flex flex-row justify-center items-center p-4 gap-4 text-lg">
                    <li>
                        <a href="https://www.instagram.com/sementeaju/" target="_blank" rel="noreferrer" className={styleIcon}>
                            <BsInstagram/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@sementeaju" target="_blank" rel="noreferrer"  className={styleIcon}>
                            <BsYoutube/>
                        </a>
                    </li>
                    <li>
                        <a href="https://wa.me/5579988172199" target="_blank" rel="noreferrer"  className={styleIcon}>
                            <BsWhatsapp/>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="flex flex-row mb-4 gap-2">
                <h4 className="text-gray-800 text-base mobile:text-[14px]">Email: </h4>
                <span className="text-gray-800 text-base mobile:text-[14px] hover:text-colorBase ">contato@sementearacaju.org.br</span>
            </div>
        </footer>
    )
}