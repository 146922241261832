export const diretoria = [  
    {
        "id": 1,
        "nome": "Allisson Goes",
        "cargo": "Presidente",
        "imagem": "./assets/img/membros/alisson.png"
    },
    {
        "id": 3,
        "nome": "Sandra Melo",
        "cargo": "Vice-Presidenta",
        "imagem": "./assets/img/membros/sandra.png"
    },
    {
        "id": 5,
        "nome": "Caio César Andrade",
        "cargo": "1º Secretário",
        "imagem": "./assets/img/membros/caio.png"
    },
    {
        "id": 4,
        "nome": "Thomas Cardoso",
        "cargo": "2º Secretário",
        "imagem": "./assets/img/membros/thomas.png"
    },
    {
        "id": 2,
        "nome": "Alana Koehne Carvalho",
        "cargo": "Tesoureira",
        "imagem": "./assets/img/membros/alana.png"
    }
]

export const pastores = [
    {
        "id": 3,
        "nome": "Sandra Melo",
        "cargo": null,
        "imagem": "./assets/img/membros/SandraPastor.png"
    },
    {
        "id": 1,
        "nome": "Allisson Goes",
        "cargo": null,
        "imagem": "./assets/img/membros/AlissonPastor.png"
    }
]