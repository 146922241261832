import React from "react";

export default function Title({ children, link }){
    return(
        <div className="flex flex-row items-center justify-center mb-10 gap-2">
            <h2 className="text-slate-800 text-2xl font-bold mobile:text-lg">{children}</h2>
            <hr className="w-[70%] border-1 border-black mobile:w-[50%] tablet:w-[60%]" />
            <a href={link} className="text-red-300 hover:text-red-400 mobile:hidden cursor-pointer">Ver mais →</a>
        </div>
    )
}