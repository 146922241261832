import React from "react";
import Title from "../Title/Title";
import CardMember from "./CardMember/CardMember";
import { diretoria } from "./data";
import { pastores } from "./data";



export default function Diretoria(){

    return(
        <section id="Diretoria" className="flex flex-col justify-center w-[100%] py-2">
            <Title>
                Diretoria
            </Title>

            <div className="flex flex-col justify-center items-center gap-4">
                <h4 className="text-[32px] font-bold text-colorBase mt-4 uppercase mobile:text-[26px]">Pastores</h4>
                <div className="flex flex-row gap-4 mobile:flex-col">
                    {pastores.map((item) => (
                        <CardMember item={item} size={false}/>
                    ))}
                </div>
            </div>

            <div className="flex flex-col justify-center items-center gap-4">
                <h4 className="text-[32px] font-bold text-colorBase mt-4 uppercase mobile:text-[26px]">Diretoria 2023-2025</h4>
                <div className="flex flex-row gap-4 mobile:flex-col justify-center flex-wrap">
                    {diretoria.map((item) => (
                        <CardMember item={item} size={true}/>
                    ))}
                </div>
            </div>
        </section>
    );
}