import React, { useState } from "react";
import Logo from './Logo';
import {GiHamburgerMenu} from 'react-icons/gi';
import {AiOutlineClose} from 'react-icons/ai';


const Nav = () => {

    const Links = [
        {name:"Semente", link:"#Semente"},
        {name:"Ministérios", link:"#Ministerios "},
        {name:"Diretoria", link:"#Diretoria "},
        {name:"Agenda", link:"#Agenda"},
        {name:"Contribua", link:"#Contribua"},
        {name:"Reflexões", link:"#Reflexoes"},
        {name:"Contato", link:"#Contato"},
    ];

    const styleIcon = "text-slate-600 hover:text-red-500 duration-500 text-lg"

    let [isOpen, setIsOpen] = useState(false)

    return (
        <div className="shadow-md w-full fixed top-0 left-0 z-10 text-center">
            <div className="md:flex items-center justify-between bg-white py-4 md:px-5 px-3 mobile:items-center h-[80px] tablet:h-[90px] mobile:h-[90px]">
                <Logo/>
                <div onClick={() => setIsOpen(!isOpen)} className="text-2sm absolute right-8 top-6 cursor-pointer md:hidden">
                    {isOpen ? <AiOutlineClose className={styleIcon}/> : <GiHamburgerMenu className={styleIcon}/>}
                </div>
                <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-1 mt-2 tablet:mt-0 transition-all duration-500 ease-in ${isOpen ? 'top-20 opacity-90' : 'top-[-490px]'} md:opacity-100 opacity-0`}>
                    {Links.map((link) => (
                            <li key={link.name} className="md:ml-8 text-2sm mobile:hover:bg-red-400">
                                <a href={link.link}className="text-slate-600 hover:text-red-500 mobile:hover:text-white duration-500">{link.name}</a>
                            </li>
                        )
                    )}
                </ul>
            </div>
        </div>
    )
}
// mobile:hover:bg-red-400mobile:hover:bg-red-400mobile:hover:bg-red-400ibparacaju@gmail.com
export default Nav