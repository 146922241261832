import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import App from './pages/App';
import Login from './pages/Login/Login';
import Sucesso from './pages/Sucess';
import Admin from "./pages/Admin/Admin";
// import { isAuthenticated } from "./auth";



export default function Rotas() {

    const token = localStorage.getItem('token');
    const [auth, setAuth] = useState(false);
    useEffect(() => {
        if(token !== null){
            setAuth(true);
        }else{
            setAuth(false);
        }
    }, [token])

    return(
        <Router>
            <Routes>
                <Route exact path='/' element={<App />} />
                <Route path='/sucesso' element={<Sucesso/>}/>
                <Route path='/login' element={<Login />} />
                <Route path='/Admin' element={auth === true ? <Admin/> : <Navigate to="/login"/>}/>
                {/* <Route path="/Admin" element={
                    isAuthenticated() ? (
                        <Admin/>
                    ) : (
                        <Navigate  to="/login"/>
                    )
                }/> */}
            </Routes>
        </Router>
    );
}