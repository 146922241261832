import React from "react";

export default function Button({children, click}){
    return(
        <div>
            <button type="button" onClick={click} className="bg-colorBase py-2 px-4 rounded-lg text-black hover:bg-red-500 duration-700 hover:text-white mobile:py-2 mobile:px-2 mobile:mb-1 mobile:text-[16px] tablet:py-2 tablet:px-4 tablet:mr-2">
                {children}
            </button>
        </div>
    )
}