import React from "react";

export default function BUttonGray({children, click}){
    return(
        <div>
            <button type="button" onClick={click} className="bg-gray-500 mt-4 py-2 px-4 rounded-lg text-white hover:font-semibold hover:bg-gray-600 duration-700 hover:text-white mobile:py-2 mobile:px-2 mobile:mb-1 mobile:text-[16px] tablet:py-2 tablet:px-4 tablet:mr-2">
                {children}
            </button>
        </div>
    )
}
