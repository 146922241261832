import React, { useEffect, useState } from "react";
import { BiLogOutCircle } from 'react-icons/bi';
import {BsFillCalendarCheckFill} from 'react-icons/bs'
import {AiOutlineDelete, AiFillEdit, AiOutlineInfoCircle} from 'react-icons/ai'
import {MdLocationOn} from 'react-icons/md'
// import Button from "../../components/Button/Button";
import { useNavigate, useParams} from "react-router-dom";
import { FormatDate } from "../../services/formatDate";
import { sliderData } from '../../components/Home/SlideNew/SliderData';
import axios from 'axios';
// import { criaPost, deletaPost, limpaCampos } from "../../services/crud";

export default function Admin(){
    
    const dados = localStorage.getItem('dadosEventos');
    const dadosCarrossel = localStorage.getItem('dadosCarrossel');
    const dadosReflexao = localStorage.getItem('dadosReflexao');
    const token = localStorage.getItem('token');

    const [arrDados, setArrDados] = useState([]);
    const [arrDadosCarrossel, setArrDadosCarrossel] = useState([]);
    const [arrDadosReflexao, setArrDadosReflexao]  = useState([]);

    const [isFileEvento, setIsFileEvento] = useState(false);
    const [isFileCarrossel, setIsFileCarrossel] = useState(false);
    const [isEdita, setIsEdit] = useState(false);
    // const [isEdit, setIsEdit] = useState(false);
    
    const [title, setTitle] = useState('');
    const [data, setData] = useState('');
    const [hora, setHora] = useState('');
    const [local, setLocal] = useState('');
    const [selectImage, setSelectImage] = useState('');

    const [imageCarrossel, setImageCarrossel] = useState('');

    const [titleReflexao, setTitleReflexao] = useState('');
    const [textReflexao, setTextReflexao] = useState('');
    const [idReflexao, setIdReflexao] = useState('');

    let { id } = useParams()
        if (id === undefined) {
        id = 1;
    }

    const navigate = useNavigate();

    const urlBase = "https://api-semente.vercel.app";

    const styles = {
        div: "flex flex-row justfy-center items-center gap-4",
        title: "text-red-400 text-[26px] mobile:text-[22px]",
        input: "placeholder:text-slate-600 placeholder:text-[1rem] bg-slate-300 border-slate-300 rounded-md py-2 pl-4 pr-3 w-[320px] shadow-sm focus:outline-red-300 sm:text-sm mobile:w-[300px]",
        icon: "text-fonteSecundaria mobile:text-[14px]",
        btnHover: "pl-4 pr-4 pt-2 pb-2 bg-red-400 hover:bg-red-600 transition-colors duration-300 ease-in-out rounded-lg mt-4 text-white font-semibold text-sm",
    }

    function carregaEventos(){
        axios.get(`${urlBase}/eventos`)
        .then(response => {
            setArrDados(response.data);
            console.log(response.data);
        })
        .catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {

        if(dados !== '' || dados !== null || dados !== undefined || dados.length !== 0){
            const arr = JSON.parse(dados)
            setArrDados(arr);
        }else{
            const eventoDefault =  {
                titulo: 'A Definir',
                data: '00',
                hora: '10:00',
                endereco: 'A definir',
                imagem: 'selectImage'
            }
            criaPost(eventoDefault)
        }
    }, [dados])

    useEffect(() => {
        if(dadosCarrossel !== '' || dadosCarrossel !== null || dadosCarrossel !== undefined || dadosCarrossel.length !== 0){
            const arr = JSON.parse(dadosCarrossel)
            setArrDadosCarrossel(arr);
        }else{
            criaPostCarrossel(sliderData)
        }
    }, [dadosCarrossel])

    useEffect(() => {
        listaPostReflexao()
    },[])

    // useEffect(() => {
    //     if(dadosReflexao !== '' || dadosReflexao !== null || dadosReflexao !== undefined || dadosReflexao.length !== 0){
    //         const arr = JSON.parse(dadosReflexao)
    //         setArrDadosReflexao(arr);
    //     }else{
    //         const reflaxaoDefault = {
    //             title: 'Adicione uma Reflexão',
    //             texto: '...reflexão'
    //         }
    //         criaPostReflexao(reflaxaoDefault)
    //     }
    // }, [dadosReflexao])

    //EVENTOS
    function listaPost(){
        const dados = localStorage.getItem('dadosEventos');
        if (dados) {
            const arr = JSON.parse(dados);
            setArrDados(arr);
        }
    }

    useEffect(() => {

        carregaEventos(); 
    }, []);

    
    function criaPost(dados){
        
        // return new Promise((resolve, reject) => {
            
        //     const novoId = Math.random().toString(10).substr(2, 2);
        //     const dadosJson = localStorage.getItem('dadosEventos');
        //     let arrDados = [];
    
        //     if(dadosJson){
        //         arrDados = JSON.parse(dadosJson);
        //     }
        
        //     arrDados.push({
        //         id: novoId,
        //         titulo: dados.titulo,
        //         data: dados.data,
        //         hora: dados.hora,
        //         endereco: dados.endereco,
        //         imagem: dados.imagem
        //     });
    
        //     const dadosAtualizados = JSON.stringify(arrDados);
    
        //     try {
        //         localStorage.setItem('dadosEventos', dadosAtualizados);
        //         resolve()
        //     } catch(error){
        //         reject(error);
        //     }
    
        // })
    
    }

    function deletaPost(dados, id){
        const listaAtual = dados.filter((postAtual) => {
            return postAtual.id !== id;
        });
        
        const dadosAtualizadosJson = JSON.stringify(listaAtual);
        localStorage.setItem('dadosEventos', dadosAtualizadosJson);
        listaPost()
        return listaAtual;
    }

    function limpaCampos(){
        document.getElementById('title').value = '';
        document.getElementById('date').value = '';
        document.getElementById('local').value = '';
        document.getElementById('imagem').value = '';
        setSelectImage('');
        setIsFileEvento(false);
    }

    async function handleOnSubmit(){
        try{
            const post = {
                titulo: title,
                data: data,
                hora: hora,
                endereco: local,
                imagem: selectImage
            }

            await criaPost(post);
            listaPost()
            limpaCampos()
        } catch(error){
            alert(error)
        }
    }

    //CARROSSEL
    function criaPostCarrossel(dados){
        return new Promise((resolve, reject) => {
            const novoId = Math.random().toString(10).substr(2, 2);
            const dadosJson = localStorage.getItem('dadosCarrossel');
            let arrDados = [];

            if(dadosJson){
                arrDados = JSON.parse(dadosJson);
            }
        
            arrDados.push({
                id: novoId,
                imagem: dados.imagem
            });
    
            const dadosAtualizados = JSON.stringify(arrDados);
    
            try {
                localStorage.setItem('dadosCarrossel', dadosAtualizados);
                resolve()
            } catch(error){
                reject(error);
            }
        })
    }

    function listaPostCarrossel(){
        const dados = localStorage.getItem('dadosCarrossel');
        if (dados) {
            const arr = JSON.parse(dados);
            setArrDadosCarrossel(arr);
        }
    }

    function deletaPostCarrossel(dados, id){
        const listaAtual = dados.filter((postAtual) => {
            return postAtual.id !== id;
        });
        
        const dadosAtualizadosJson = JSON.stringify(listaAtual);
        localStorage.setItem('dadosCarrossel', dadosAtualizadosJson);
        listaPost()
        return listaAtual;
    }

    function limpaCamposcarrossel(){
        document.getElementById('imagemCarrosel').value = '';
        setImageCarrossel('');
        setIsFileCarrossel(false);
    }

    //REFLEXÃO
    // function criaPostReflexao(dados){
    //     return new Promise((resolve, reject) => {
    //         const novoId = Math.random().toString(10).substr(2, 2);
    //         const dadosJson = localStorage.getItem('dadosReflexao');
    //         let arrDados = [];

    //         if(dadosJson){
    //             arrDados = JSON.parse(dadosJson);
    //         }
        
    //         arrDados.push({
    //             id: novoId,
    //             title: dados.title,
    //             texto: dados.texto
    //         });
    
    //         const dadosAtualizados = JSON.stringify(arrDados);
    
    //         try {
    //             localStorage.setItem('dadosReflexao', dadosAtualizados);
    //             resolve()
    //         } catch(error){
    //             reject(error);
    //         }
    //     })
    // }

    function listaPostReflexao(){
        const dados = localStorage.getItem('dadosReflexao');
        if (dados) {
            const arr = JSON.parse(dados);
            setArrDadosReflexao(arr);
        }
    }

    function editaPostReflexao(id, dados) {
        return new Promise((resolve, reject) => {
            const dadosJson = localStorage.getItem('dadosReflexao');
            let arrDados = [];
        
            if (dadosJson) {
                arrDados = JSON.parse(dadosJson);
            }
        
            const indice = arrDados.findIndex((post) => post.id === id);
        
            if (indice === -1) {
                reject('Post não encontrado');
                return;
            }
        
            arrDados[indice] = {
                id: id,
                title: dados.title,
                texto: dados.texto,
            };
        
            const dadosAtualizados = JSON.stringify(arrDados);
        
            try {
                localStorage.setItem('dadosReflexao', dadosAtualizados);
                listaPostReflexao()
                setIsEdit(false)
                limpaCamposEdita()
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    function preenchedadosReflexao(){
        let arrDados = [];
        setIsEdit(true);
        const dados = localStorage.getItem('dadosReflexao');
        arrDados = JSON.parse(dados);
        console.log(arrDados)
        document.getElementById('reflexaoTitle').value = arrDados[0].title;
        document.getElementById('reflexaoTexto').value = arrDados[0].texto;
    }

    // async function handleOnSubmitReflexao(){
    //     try{
    //         const post = {
    //             title: titleReflexao,
    //             texto: textReflexao
    //         }

    //         await criaPostReflexao(post);
    //         listaPostReflexao()
    //         // limpaCampos()
    //     } catch(error){
    //         alert(error)
    //     }
    // }

    async function handleSubmitEdita(){
        let titulo = '';
        let texto = '';

        if(titleReflexao !== ''){
            titulo = titleReflexao
        }else{
            titulo = document.getElementById('reflexaoTitle').value;
        }

        if(textReflexao !== ''){
            texto = textReflexao
        }else{
            texto = document.getElementById('reflexaoTexto').value;
        }

        const editaPost = {
            title: titulo,
            texto: texto
        }
        
        editaPostReflexao(idReflexao, editaPost)
    }

    function limpaCamposEdita(){
        document.getElementById('reflexaoTitle').value = '';
        document.getElementById('reflexaoTexto').value = '';
    }

    const handleChangeTitleReflexao = (e) => {
        setTitleReflexao(e.target.value);
    }
    const handleChangeTextoReflexao = (e) => {
        setTextReflexao(e.target.value);
    }

    async function handleOnSubmitCarrossel(){
        try{
            const post = {
                imagem: imageCarrossel
            }
            await criaPostCarrossel(post);
            listaPostCarrossel()
            limpaCamposcarrossel()

        } catch(error){
            alert(error)
        }
    }

    const handleChangeTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleChangeData = (e) => {
        let dataSelecionada = e.target.value;

        let date = dataSelecionada.split('T')[0];
        setData(FormatDate(date))

        let hora = dataSelecionada.split('T')[1];
        setHora(hora)
    }

    const handleChangeLocal = (e) => {
        setLocal(e.target.value);
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setSelectImage(e.target.result);
        };
    
        if (file) {
            reader.readAsDataURL(file);
            setIsFileEvento(true)
        } else{
            setIsFileEvento(false);
        }
    }

    const handleImageChangeCarrossel = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setImageCarrossel(e.target.result);
        };
    
        if (file) {
            reader.readAsDataURL(file);
            setIsFileCarrossel(true)
        } else{
            setIsFileCarrossel(false);
        }
    }

    function desloga(){
        localStorage.setItem('token', null);
        navigate("/")

    }

    return (
        <div className="bg-gray w-[100%]">
            <div className="flex justify-start">
                <button onClick={() => desloga()} className="text-[44px] text-red-500 hover:text-red-700 ml-4 mt-4 mobile:text-[32px]">
                    <BiLogOutCircle />
                </button>
            </div>
            <div className="flex items-center justify-center">
                <h2 className="text-red-400 text-[30px] font-semibold mobile:mt-4 mobile:mb-[-14px]">Configurar Conteúdos</h2>
            </div>
            <section className="flex flex-row mobile:flex-col">
                <form 
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleOnSubmit()
                    }} 
                    className="flex flex-col justify-center items-center gap-4 p-[20px] bg-gray-200 shadow-sm w-[40%] h-auto mx-auto my-auto mt-20 rounded-lg mobile:w-[95%]"
                >
                    <h3 className={styles.title}>Adicionar Evento</h3>
                    <div className={styles.div}>
                        <label htmlFor="title">
                            Titulo: 
                        </label>
                        <input id="title" onChange={handleChangeTitle} type="text" name="title" placeholder="" autoComplete="off" className={styles.input} />
                    </div>
                    <div className={styles.div}>
                        <label htmlFor="date">
                            Data: 
                        </label>
                        <input id="date" onChange={handleChangeData} type="datetime-local" name="date" className={styles.input} />
                    </div>
                    
                    <div className={styles.div}>
                        <label htmlFor="local">
                            Local: 
                        </label>
                        <input id="local" onChange={handleChangeLocal} type="text" name="local" className={styles.input} />
                    </div>
                    <div className={styles.div}>
                        <label htmlFor="imagem">
                            Foto: 
                        </label>
                        <input id="imagem" onChange={handleImageChange} type="file" accept="image/*" name="imagem" className={styles.input} />
                    </div>
                    {
                        isFileEvento === true ? 
                            <div className="bg-slate-300 border-slate-300 rounded-md py-2 pl-4 pr-3 w-[100%] shadow-sm ml-0 mr-0">
                                <img className="" id="imagemSelecionada" src={selectImage} alt=""/>
                            </div>
                        :
                            ''
                    }

                    <div className="flex justify-center">
                        <button type="submit" className="pl-4 pr-4 pt-2 pb-2 bg-red-400 hover:bg-red-600 rounded-lg mt-4 text-white font-semibold text-sm" disabled={arrDados.length >= 6 ? true : false}>Adicionar</button>
                    </div>
                    
                </form>

                <div className="flex flex-col justify-center items-center gap-4 p-[20px] bg-gray-200 shadow-sm w-[50%] h-auto mx-auto my-auto mt-20 rounded-lg mobile:w-[100%]">
                    <h3 className={styles.title}>Preview Eventos</h3>

                    <ul className="flex flex-col w-[100%] justify-center items-center gap-8 tablet:gap-4">
                        {arrDados.map((item) => {
                            return (
                                <li key={item.id}  className="flex flex-row justify-between items-center bg-white drop-shadow-lg p-4 w-[100%] h-[180px] rounded-lg mobile:flex-row">
                                    <div className="w-[30%] mobile:w-[100%]">
                                        <img src={item.imagem} alt="" className="w-[100%] h-40 mobile:w-[100%] mobile:h-[80] mobile:p-1"/>
                                    </div>

                                    <div className="w-[40%] mobile:w-[100%] tablet:w-[35%]">
                                        <h3 className="text-fontePrincipal text-lg font-bold mobile:text-[16px] tablet:text-2sm">{item.titulo}</h3>
                                        <div className="flex flex-row gap-2 justify-start items-center">
                                            <BsFillCalendarCheckFill className={styles.icon}/>
                                            <p className="mobile:text-[14px] ">{item.data} - {item.hora}</p>
                                        </div>
                                        <div className="flex flex-row gap-2 justify-start items-center">
                                            <MdLocationOn className={styles.icon}/>
                                            <p className="mobile:text-[14px]">{item.endereco}</p>
                                        </div>
                                    </div>

                                    <button className="text-[30px] text-red-400 hover:text-red-600 cursor-pointer mobile:text-[28px]" disabled={arrDados.length <= 1 ? true : false} onClick={() => deletaPost(arrDados, item.id)}>
                                        <AiOutlineDelete />
                                    </button>
                                    {/* {isEdit === true
                                        ?
                                            <button className="text-[30px] text-red-400 hover:text-red-600 cursor-pointer" onClick={() => deletaPost(arrDados, item.id)}>
                                                <AiFillEdit />
                                            </button>
                                        :
                                            <button className="text-[30px] text-red-400 hover:text-red-600 cursor-pointer" disabled={arrDados.length <= 1 ? true : false} onClick={() => deletaPost(arrDados, item.id)}>
                                                <AiOutlineDelete />
                                            </button>
                                    } */}
                                    
                                    
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </section>
            
            <section className="flex flex-row mobile:flex-col">
                <form onSubmit={(e) => {
                        e.preventDefault();
                        handleOnSubmitCarrossel()
                    }} 
                    action="" 
                    className="flex flex-col justify-center items-center gap-4 p-[20px] bg-gray-200 shadow-sm w-[40%] h-auto mx-auto my-auto mt-20 rounded-lg mobile:w-[100%]"
                >
                    <h3 className={styles.title}>Adicionar Imagem ao Carrossel</h3>
                    <div className="flex flex-row items-center justify-around gap-4">
                        <AiOutlineInfoCircle className="text-[20px] text-red-400"/>
                        <p className="text-gray-600 text-[14px]">Para melhor visualização, adicione imagens com resolução (800px à 1200px)</p>
                    </div>
                    <div className={styles.div}>
                        <label htmlFor="imagemCarrossel">
                            Foto: 
                        </label>
                        <input id="imagemCarrosel" onChange={handleImageChangeCarrossel} type="file" accept="image/*" name="imagemCarrosel" className={styles.input}/>
                    </div>
                    {
                        isFileCarrossel === true ? 
                            <div className="bg-slate-300 border-slate-300 rounded-md py-2 pl-4 pr-3 w-[100%] shadow-sm ml-0 mr-0">
                                <img className="" id="imagemSelecionadaCarrossel" src={imageCarrossel} alt=""/>
                            </div>
                        :
                            ''
                    }
                    <div className="flex justify-center">
                        <button type="submit" className="pl-4 pr-4 pt-2 pb-2 bg-red-400 hover:bg-red-600 rounded-lg mt-4 text-white font-semibold text-sm">Adicionar</button>
                    </div>
                </form>

                <div className="flex flex-col justify-center items-center gap-4 p-[20px] bg-gray-200 shadow-sm w-[50%] h-auto mx-auto my-auto mt-20 rounded-lg mobile:w-[100%]">
                    <h3 className={styles.title}>Preview Carrossel</h3>
                    <ul className="flex flex-col w-[100%] justify-center items-center gap-8 tablet:gap-4">
                        {arrDadosCarrossel.map((item) => {
                            return (
                                <li key={item.id}  className="flex flex-row justify-start items-center bg-white drop-shadow-lg p-4 w-[100%] h-[450px] rounded-lg mobile:flex-col mobile:h-[auto]">
                                    <img src={item.imagem} alt="" className="w-[96%] h-[100%] mobile:w-[100%]  mobile:p-1"/>
                                    <button className="text-[30px] text-red-400 hover:text-red-600 cursor-pointer" disabled={arrDadosCarrossel.length <= 1 ? true : false} onClick={() => deletaPostCarrossel(arrDadosCarrossel, item.id)}>
                                        <AiOutlineDelete />
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </section>
            <section className="flex flex-row mobile:flex-col mb-20">
                <form onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmitEdita()

                        // if(isEdita === false){
                        //     handleOnSubmitReflexao()
                        // }else{
                        //     handleSubmitEdita()
                        // }
                    }} 
                    action="" 
                    className="flex flex-col justify-center items-center gap-4 p-[20px] bg-gray-200 shadow-sm w-[40%] h-auto mx-auto my-auto mt-20 rounded-lg mobile:w-[100%]"
                >
                    <h3 className={styles.title}>Editar Texto Reflexão</h3>
                    <div className={styles.div}>
                        <label htmlFor="reflexaoTitle">
                            Titulo: 
                        </label>
                        <input id="reflexaoTitle" onChange={handleChangeTitleReflexao} type="text" name="reflexaoTitle" className={styles.input} />
                    </div>
                    <div className={styles.div}>
                        <label htmlFor="reflexaoTexto">
                            Texto: 
                        </label>
                        <textarea name="reflexaoTexto" onChange={handleChangeTextoReflexao} id="reflexaoTexto" cols="40" rows="8" maxLength={200} className={styles.input}></textarea>
                    </div>
                    <div className="flex justify-center">
                        <button type="submit" className={styles.btnHover} >Salvar</button>
                    </div>

                    {/* {isEdita === true 
                    ?
                        <div className="flex justify-center">
                            <button type="submit" className="pl-4 pr-4 pt-2 pb-2 bg-red-400 hover:bg-red-600 rounded-lg mt-4 text-white font-semibold text-sm">Editar</button>
                        </div>
                    :
                        <div className="flex justify-center">
                            <button type="submit" className="pl-4 pr-4 pt-2 pb-2 bg-red-400 hover:bg-red-600 rounded-lg mt-4 text-white font-semibold text-sm" disabled={arrDadosReflexao === 1 ? true : false}>Adicionar</button>
                        </div>
                    } */}
                    
                </form>
                <div className="flex flex-col justify-center items-center gap-4 p-[20px] bg-gray-200 shadow-sm w-[50%] h-auto mx-auto my-auto mt-20 rounded-lg mobile:w-[100%]">
                    <h3 className={styles.title}>Preview Reflexão</h3>
                    {arrDadosReflexao.map((item) => {
                        return (
                            <article key={item.id} className="flex flex-row justify-around items-center bg-white drop-shadow-lg p-4 w-[80%] h-[200px] rounded-lg mobile:w-[100%] mobile:flex-row mobile:h-[220px]">
                                <div className="flex flex-col">
                                    <h4 className="text-gray-800 text-[22px] mobile:text-[18px] mobile:text-center">{item.title}</h4>
                                    <p className="w-80 h-30 overflow-auto mobile:mt-4 mobile:w-60 mobile:h-20">{item.texto}</p>
                                </div>

                                <button className="text-[30px] text-red-400 hover:text-red-600 cursor-pointer" onClick={() => {
                                    setIdReflexao(item.id)
                                    preenchedadosReflexao()
                                }}>
                                    <AiFillEdit className="text-[20px] mt-10"/>
                                </button>
                            </article>
                        )
                    })}
                </div>
            </section>
        </div>
    )
}