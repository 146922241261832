import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Sucesso(){

    const navigate = useNavigate();

    let { id } = useParams()
    if (id === undefined) {
    id = 1;
    }

    return(
        <section className="flex flex-col justify-center items-center">
            <img src="https://media.giphy.com/media/YlSR3n9yZrxfgVzagm/giphy.gif" alt="Imagem de Sucesso"/>
            <h2 className="text-slate-500 text-lg">Formulário enviado com sucesso!</h2>
            <button className="pl-4 pr-4 pt-2 pb-2 bg-red-400 rounded-lg mt-4 text-white font-semibold text-2sm" onClick={() => navigate(-1)}>Voltar</button>
        </section>
    );
}