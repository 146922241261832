import styled from "styled-components";


export const section = styled.section`
  .rec.rec-arrow{
    display: none;
    color: white
  }
  .rec .rec-dot{
    background-color: #b34b68;
    box-shadow: none;
    margin-top: -40px;
    z-index: 1;
    width: 16px;
    height: 16px;
  }
  .rec .rec-dot_active{
    background-color: #9f1239;
    
  }
`;
