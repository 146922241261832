import React from "react";
import * as S from './style.js'
import SliderNew from "./SlideNew/SliderNew.jsx";

export default function Home() {
  

  return (
    <S.section id="Home" className="flex flex-col w-full justify-center mt-[59px] ">
      <SliderNew/>
    </S.section>
  );
}
