import React, { useState } from "react";
import copy from "copy-to-clipboard"; 
import Button from "../Button/Button";
import qrCode from '../../assets/img/qrcodeCNPJ.png'
import Pix from './Pix/Pix';
import { FaCopy, FaClipboardCheck } from 'react-icons/fa';

export default function Modal(props){
    const [isCopiedCnpj, setIsCopiedCnpj] = useState(false);
    const [isCopiedEmail, setIsCopiedEmail] = useState(false);

    const textToCopyCNPJ = '52110974000121';
    const textToCopyEMAIL = 'pix@sementearacaju.org.br';

    const styleText = "text-slate-800 text-[16px] font-bold mobile:text-base";

    function changeIcon(tipo) {
        if(tipo === 'cnpj'){
            copy(textToCopyCNPJ)
            setIsCopiedCnpj(true)
        }

        if(tipo === 'email'){
            copy(textToCopyEMAIL)
            setIsCopiedEmail(true)
        }
    }


    setTimeout(() => {
        setIsCopiedCnpj(false)
        setIsCopiedEmail(false)
    }, 10000)

    return(
        <div className="flex justify-center items-center rounded-lg  p-2 fixed top-0 left-0 w-[100%] h-[100%] bg-modal z-20 ">
            <div className="bg-bgModal drop-shadow-lg rounded-lg p-6 mobile:w-[80%]">
                <div className="flex justify-center mb-4 mobile:hidden">
                    <Pix />
                </div>
                <h2 className="text-2sm text-center font-bold text-slate-800 mb-4 mobile:text-sm">Faça sua Contribuição</h2>
                <div className="mb-4 flex justify-center">
                    <img className="w-[100%] mobile:w-[90%]" src={qrCode} alt="Imagem do QRCode Pix"/>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between gap-1 items-center">
                        <div>
                            <label className={styleText}>Cnpj: </label>
                            <span id="textCopy" className="mobile:text-base">52.110.974/0001-21</span>
                        </div>
                        <div className="ml-2" >
                            <button type="button" id="copyBtnCnpj" title="Copiar chave pix" onClick={() => changeIcon('cnpj')} className="py-1 px-2 bg-transparent duration-1000 rounded-lg text-lg text-slate-600">
                                {isCopiedCnpj === true ? <FaClipboardCheck/> : <FaCopy className="hover:text-colorBase"/>}
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between gap-1 items-center">
                        <div>
                            <label className={styleText}>Email: </label>
                            <span id="textCopy" className="mobile:text-base">pix@sementearacaju.org.br</span>
                        </div>
                        <div className="ml-2" >
                            <button type="button" id="copyBtnEmail" title="Copiar chave pix" onClick={() => changeIcon('email')} className="py-1 px-2 bg-transparent duration-1000 rounded-lg text-lg text-slate-600">
                                {isCopiedEmail === true ? <FaClipboardCheck/> : <FaCopy className="hover:text-colorBase"/>}
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row gap-1 items-center">
                        <label className={styleText}>Nome: </label>
                        <span>Igreja Batista Semente</span>
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <Button click={props.closeModal}>Fechar</Button>
                </div>
            </div>
        </div>
    )
}