import React, { useEffect, useState } from "react";
import Title from '../Title/Title';
import Videos from "../Videos/Videos";
import axios from 'axios';
import { AiOutlineLink } from 'react-icons/ai';

// import Card from "./Card Semente/Card";

export default function Reflexoes() {
    var require = "../../assets/docs/carta.pdf";

    const [arrDados, setArrDados] = useState([]);

    const urlBase = "https://api-semente.vercel.app"
    // const urlBase = "http://localhost:4000"

    useEffect(() => {
        carregaReflexao()
    },[]);

    function carregaReflexao(){
        axios.get(`${urlBase}/reflexao`)
        .then(response => {
            setArrDados(response.data)
        })
        .catch(error => {
            alert(error)
        })
    };

    // const dados = localStorage.getItem('dadosReflexao');
    // const arrDados = JSON.parse(dados);

    // const styleLinks = "flex flex-row items-center gap-2 text-gray-300 text-[20px] font-normal hover:text-gray-100 mobile:text-[16px]";

    return (
        <section id="Reflexoes" className="flex flex-col justify-center mt-10">
            <Title>
                Reflexões
            </Title>
            <div className="flex flex-col justify-center items-center gap-20 p-8 mobile:flex-col mobile:gap-10">
                {arrDados.map((item) => {
                    return (
                        <article key={item.id} className="w-[620px] flex flex-col justify-start mobile:justify-center mobile:items-center mobile:w-[320px]">
                            <h4 className="text-gray-700 text-[20px] font-semibold mobile:text-[18px] mobile:text-center">{item.titulo}</h4>
                            <br />
                            <p className="text-gray-600 mobile:text-center">{item.texto}</p>
                            <a href={item.link} target="_blank" rel="noreferrer" className="text-colorBase hover:text-red-700 mt-2">Ver mais...</a>
                        </article>
                    )
                })}
            </div>

            {/* <div className="flex flex-col justify-center items-center gap-2 w-[100%] h-[400px] mt-20 mb-10 bg-colorBase bg-center bg-cover">
                <h3 className="uppercase text-lg font-bold text-center text-white mobile:text-sm">Links</h3>
                <ul className="flex flex-col gap-4 ">
                    <li className={styleLinks}>
                        <AiOutlineLink/>
                        <a rel="stylesheet" href="www.facebook.com" target="_blank">Acesse aqui nosso site e descubra as novidade!</a> 
                    </li>
                    <li className={styleLinks}>
                        <AiOutlineLink/>
                        <a rel="stylesheet" href="www.facebook.com" target="_blank">Acesse aqui nosso site e descubra as novidade!</a> 
                    </li>
                    <li className={styleLinks}>
                        <AiOutlineLink/>
                        <a rel="stylesheet" href="www.facebook.com" target="_blank">Acesse aqui nosso site e descubra as novidade!</a> 
                    </li>
                </ul>
            </div> */}
        </section>
    );
}
