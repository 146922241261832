import React from "react";
import semente from '../../assets/img/sementes/sementes.png';
import Estatuto from "../Estatuto/Estatuto";

export default function About(){
	const styleStrong = 'text-red-700';

	return(
		<section id="Semente" className="flex flex-col justify-center items-center p-8 h-[900px] mobile:h-[520px] mobile:mb-10 mobile:mt-10">
			<div className="flex flex-row justify-center items-center">
				<div className="flex flex-col mobile:justify-center mobile:items-center tablet:ml-20">
					<h3 className="text-2xl mb-4 text-slate-800 font-bold mobile:text-lg">Semente</h3>
					<article className="w-[520px] h-64 text-[16px] mobile:w-[320px] overflow-scroll">
						<p>A palavra grega <em>ekklesia</em> aparece pouco mais de cem vezes no novo testamento, sendo o livro dos Atos dos Apóstolos o livro que mais utiliza essa expressão. Ela significa literalmente <strong className={styleStrong}>assembleia</strong>, <strong className={styleStrong}>convocada</strong>, <strong className={styleStrong}>escolhida</strong>. Interpretando de maneira mais simples, a igreja aponta para o povo chamado por Deus para viver e anunciar o Reino de Deus.</p>

						<p>A Semente quando se reúne para celebrar a Deus Pai/Mãe, servir ao próximo e anunciar a graça incondicional através da morte e ressurreição de Jesus está assumindo o seu papel de igreja/ekklesia, como agente em prol do Reino de Deus que foi inaugurado com a vinda do carpinteiro de Nazaré.</p>
						<br />

						<p>Somos igreja de Jesus em sua <em>multidiversidade</em>. Se olharmos atentamente por alguns minutos para os Evangelhos (Mateus, Marcos, Lucas e João), cada um foi escrito por muitas mãos, com suas próprias experiências de fé e com o propósito de comunicar de maneira particular às suas respectivas comunidades. Depois, voltemo-nos para o ministério de Paulo, o apóstolo, e suas viagens missionárias que mostravam a diversidade das primeiras comunidades cristãs. Tudo isso nos dá margem para dizer que a igreja do Senhor é formada na e pela diversidade. Como Corpo de Jesus que reúne membros com características diferentes, a Semente afirma todas as pessoas como beneficiárias legítimas da multiforme graça de Deus (1Pe. 4:10), independente de sua cor/raça/etnia, identidade de gênero, orientação sexual, classe, origem geográfica etc.</p>
						<br />

						<p>Somos igreja para além das quatro paredes da instituição. A Igreja Batista Semente não se reduz a uma placa ou CNPJ. Ela é rede de relações e apoio, de afetos construídos e alimentados pela presença inspiradora do Espírito Santo, a Ruah Divina. Ela é espaço para ouvir e conhecer histórias de vida.</p>

						<p>Você se sente chamada/a/e para viver a mensagem do Reino de Deus trazida por Jesus? Venha <strong className={styleStrong}>SER IGREJA</strong> com a gente!</p>
						<br />

						<h2>MISSÃO</h2>
						<p>Anunciar as boas novas do Jesus preto, pobre e periférico de Nazaré, reafirmando todas as possibilidades de vida e existência, estimulando a reconciliação das pessoas entre si, destas com o seu Criador e com o meio ambiente onde vivem, sendo uma igreja batista, afirmativa, acolhedora e ecumênica.</p>
						<br />

						<h2>VISÃO</h2>
						<p>Ser em Aracaju um sinal histórico do Reino de Deus, cuidando e defendendo a Criação, a dignidade de todas as pessoas e testemunhando o Evangelho de Jesus Cristo que é amor, justiça e paz.</p>
						<br />
						<h2>VALORES</h2>
						<ol>
							<li>- Jesus Cristo de Nazaré, através do seu testemunho que chegou até nós, por meio dos relatos bíblicos, como suprema autoridade em matéria de fé e conduta;</li>
							<li>- Defesa da dignidade de todas as pessoas;</li>
							<li>- Cuidado com a Criação, revelada na atuação local;</li>
							<li>- Liberdade e autonomia do Ser Humano na interpretação das Escrituras e no relacionamento com o Divino;</li>
							<li>- Autonomia da igreja local para organizar sua vida e missão;</li>
							<li>- Defesa da liberdade de crença e de expressão de fé para todas as pessoas e todos os credos;</li>
							<li>- Separação institucional entre Igreja e Estado.</li>
						</ol>

					</article>
				</div>
				<div className="flex flex-col gap-4 items-center justify-center mobile:hidden tablet-2:hidden">
					<img src={semente} alt="" className="w-[50%] rounded-xl"/>
					<p className="text-[14px] w-80">Você se sente chamada/a/e para viver a mensagem do Reino de Deus trazida por Jesus? Venha <strong className="text-red-400">SER IGREJA</strong>  com a gente!</p>
				</div>
			</div>
			<div className="p-8">
				<Estatuto/>
			</div>
		</section>
	);
}