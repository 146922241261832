import React, {useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdEmail, MdPassword } from 'react-icons/md';
import { AiOutlineHome } from 'react-icons/ai';
import Modal from "./ComponentModal/Modal";
import { v4 as uuidv4 } from 'uuid';

export default function Login(){
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [erroLogin, setErroLogin] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const token = uuidv4();
    let styleDiv = "flex flex-row justfy-center items-center gap-4";

    let { id } = useParams()
        if (id === undefined) {
        id = 1;
    }

    let usuarios = [
        {user: "jennyfer", password: "123456"},
        {user: "sementearacaju@gmail.com", password: "jesus100"}
    ]

    function closeModal(){
        setIsOpen(false);
    }

    function handleChangeEmail(e){
        setEmail(e.target.value);
    }
    function handleChangeSenha(e){
        setSenha(e.target.value);
    }

    function autentica(){
        usuarios.map((item) => {
            if(email === item.user && senha === item.password){
                navigate("/Admin")
                setErroLogin(false)
                localStorage.setItem('token', token);
            }else{
                setErroLogin(true)
            }
        })
    }

    return (
        <div id="Admin" className="bg-gray w-[100%]">
            <div className="flex justify-start">
                <button onClick={() => navigate('/')} className="text-[44px] text-red-500 hover:text-red-700 ml-4 mt-4 mobile:text-[32px]">
                    <AiOutlineHome />
                </button>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 p-[20px] bg-white shadow-sm w-[40%] h-[500px] mx-auto my-auto mt-20 rounded-lg mobile:w-[95%]">
                <h3 className="text-red-400 text-[44px]">Login</h3>
                <div className={styleDiv}>
                    <label htmlFor="email">
                        <MdEmail className="text-2sm text-red-500" />
                    </label>
                    <input id="email" value={email} onChange={handleChangeEmail} type="email" name="email" placeholder="Seu e-mail" autoComplete="off" className="placeholder:text-slate-600 placeholder:text-[1rem] bg-slate-300 border-slate-300 rounded-md py-2 pl-4 pr-3 w-[320px] shadow-sm focus:outline-red-300 sm:text-sm" />
                </div>
                {
                    erroLogin && (
                        <span className="text-red-400 mt-[-18px]" >Por favor insira email corretamente!</span>
                    )
                }

                <div className={styleDiv}>
                    <label htmlFor="email">
                        <MdPassword className="text-2sm text-red-500" />
                    </label>
                    <input id="senha" value={senha} onChange={handleChangeSenha} type="password" name="email" placeholder="Sua senha" autoComplete="off" className="placeholder:text-slate-600 placeholder:text-[1rem] bg-slate-300 border-slate-300 rounded-md py-2 pl-4 pr-3 w-[320px] shadow-sm focus:outline-red-300 sm:text-sm" />
                </div>
                {
                    erroLogin && (
                        <span className="text-red-400 mt-[-18px]" >Por favor insira senha corretamente!</span>
                    )
                }

                {/* <div>
                    <span className="cursor-pointer hover:text-red-400" onClick={() => setIsOpen(true)}>Esqueceu a senha?</span>
                    {isOpen && <Modal closeModal={closeModal}/>}
                </div> */}

                <div className="flex justify-center items-center w-[100%]">
                    {/* <button className="pl-4 pr-4 pt-2 pb-2 bg-red-400 rounded-lg mt-4 text-white font-semibold text-sm" onClick={() => navigate(-1)}>Voltar</button> */}
                    <button className="pl-4 pr-4 pt-2 pb-2 bg-red-400 rounded-lg mt-4 text-white font-semibold text-sm" onClick={() => autentica()}>Acessar</button>
                </div>
            </div>

        </div>
    )
}