import React, { useState } from "react";
// import Button from "../Button/Button";
import BUttonGray from "../ButtonGray/ButtonGray";
import Modal from "../Modal/Modal";


export default function Donations(){
    const [isOpen, setIsOpen] = useState(false);

    function closeModal(){
        setIsOpen(false);
    }

    return(
        <section id="Contribua" className="flex flex-col justify-center items-center gap-2 w-[100%] h-[400px] mobile:h-[600px] tablet:h-[450px] mt-20 mb-10 bg-baseMinisterio bg-center bg-cover">
                <h4 className="uppercase text-lg font-bold text-white mobile:text-2sm">Seja Generoso</h4>
                <p className="w-[40%] text-center text-gray-300 mobile:text-sm-[12px] mobile:w-[280px]">Nossa contribuição para a construção do Reino de Deus deve ser integral, ou seja, ela envolve a nossa vida, tempo, orações e recursos. Tudo isso é indispensável para que possamos servir às pessoas. Sabemos que parte desse serviço envolve a contribuição financeira de todos aqueles e aquelas que direta ou indiretamente são abençoados(as) pela Semente. Convidamos você a exercitar sua generosidade apoiando nossa igreja (2Co 9,7).</p>
                <BUttonGray click={() => setIsOpen(true)}>Veja como Contribuir</BUttonGray>
                {isOpen && <Modal closeModal={closeModal} />}

        </section>
    );
}