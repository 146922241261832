import React from "react";
// import { useNavigate, useParams } from 'react-router-dom'
import Title from "../Title/Title";
import { BsPersonCircle, BsFillChatRightTextFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
// import emailjs from '@emailjs/browser';

export default function Contato(){

    // const navigate = useNavigate();
    //     let { id } = useParams()
    //     if(id === undefined) {
    //         id = 1;
    //     }

    // function sendEmail(e) {
    //     e.preventDefault();

    //     emailjs.sendForm(
    //         "service_7u4p2pp",
    //         "template_esm7i89",
    //         e.target,
    //         "HEKCota6f2m0KNh7V"
    //     )
    //     .then((result) => {
    //         navigate(`/sucesso`)
    //     }, (error) => {
    //         alert(error.message)
    //     });
    //     e.target.reset();
    //     }
    function enviar(e){
        e.preventDefault()
    }

    // const styleIcon = "text-red-400 hover:text-red-500 cursor-pointer";

    return (
        <section id="Contato" className="flex flex-col justify-center mt-10 h-[500px]">
            <Title>Contato</Title>
            <form onSubmit={enviar} className="flex flex-col justify-around gap-4 items-center">
                <div className="flex flex-row justify-center items-center gap-2">
                    <label htmlFor="nome">
                        <BsPersonCircle className="text-2sm text-colorBase" />
                    </label>
                    <input type="text" name="nome" placeholder="Seu nome" autoComplete="off"  className="placeholder:text-slate-600 placeholder:text-[1rem] bg-slate-300 rounded-md py-2 pl-4 pr-3 w-[320px] shadow-sm focus:outline-red-300 sm:text-sm" />
                </div>
                <div className="flex flex-row justify-center items-center gap-2">
                    <label htmlFor="email">
                        <MdEmail className="text-2sm text-colorBase" />
                    </label>
                    <input type="email" name="email" placeholder="Seu e-mail" autoComplete="off" className="placeholder:text-slate-600 placeholder:text-[1rem] bg-slate-300 border-slate-300 rounded-md py-2 pl-4 pr-3 w-[320px] shadow-sm focus:outline-red-300 sm:text-sm" />
                </div>
                <div className="flex flex-row justify-center items-center gap-2">
                    <label htmlFor="nome">
                        <BsFillChatRightTextFill className="text-2sm text-colorBase" />
                    </label>
                    <textarea type="text" name="nome" placeholder="Digite algo..." autoComplete="off"  cols={22} rows={4}  className="placeholder:text-slate-600 placeholder:text-[1rem] bg-slate-300 border-slate-300 rounded-md py-2 pl-4 pr-3 w-[320px] shadow-sm focus:outline-red-300   sm:text-sm" />
                </div>
                <div className="flex justify-center">
                    <button type="submit" className="py-2 px-4 rounded-md mt-8 bg-colorBase hover:bg-red-500 duration-700 hover:text-white">Enviar</button>
                </div>
            </form>
        </section>
    );
}