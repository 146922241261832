export const DadosAgenda = [
    {
        "id": 1,
        "alt": "Imagem com informações da agenda da Semente",
        "imagem": "./assets/img/agenda/agenda1.jpg"
    },
    {
        "id": 2,
        "alt": "Imagem com informações da agenda da Semente",
        "imagem": "./assets/img/agenda/agenda2.jpg"
    }
];