// import Cultos from "../components/Cultos/Cultos";
import Donations from "../components/Donations/Donations";
import Eventos from "../components/Eventos/Eventos";
import Header from "../components/Header/Header";
import Home from "../components/Home/Home";
import About from "../components/Sobre Nos/About";
// import Carta from "../components/Carta/Carta";
import Testemunhos from "../components/Testemunhos/Reflexao";
import Footer from "../components/Footer/Footer";
import Contato from "../components/Contato/Contato";
import Ministerios from "../components/Ministerios/Ministerios";
import Diretoria from "../components/Diretoria/Diretoria";


function App() {

  const DEFAULT_DATA = [
    {
      id: 1,
      titulo: 'A Definir',
      data: '00',
      hora: '10:00',
      endereco: 'A definir',
      imagem: 'selectImage'
    }
  ]

  const DEFAULT_DATA_IMAGEM = [
    {
      id: 1,
      imagem: ''
    }
  ]

  const DEFAULT_DATA_REFLEXAO = [
    {
      id: 1,
      title: 'Reflexão',
      texto: '...reflexão'
    }
  ]

  const storeData = localStorage.getItem('dadosEventos');
  const storeDataImagem = localStorage.getItem('dadosCarrossel');
  const storeDataReflexao = localStorage.getItem('dadosReflexao');

  if(!storeData){
    const defaultDataJson = JSON.stringify(DEFAULT_DATA);
    localStorage.setItem('dadosEventos', defaultDataJson)
  }
  if(!storeDataImagem){
    const defaultDataJson = JSON.stringify(DEFAULT_DATA_IMAGEM);
    localStorage.setItem('dadosCarrossel', defaultDataJson)
  }
  if(!storeDataReflexao){
    const defaultDataJson = JSON.stringify(DEFAULT_DATA_REFLEXAO);
    localStorage.setItem('dadosReflexao', defaultDataJson)
  }

  return (
    <>
      <Header />
      <Home />
      <About />
      <Ministerios/>
      <Diretoria/>
      <Eventos/>
      <Donations/>
      <Testemunhos/>
      <Contato />
      <Footer/>
    </>
  );
}

export default App;
