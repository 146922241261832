import React from "react";
import logo from "../../../assets/img/logo.jpeg";


const Logo = () => {
    return (
        <div className="flex items-center w-[70px]  mobile:w-[74px] mobile:mt-[-10px] tablet-2:w-[70px] ">
            <a href="#Home" className="cursor-pointer">
                <img src={logo} alt="" className="rounded-full w-[100%] ml-5"/>
            </a>
        </div>
    )
}

export default Logo