import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './pages/App';
import Rotas from './router';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Rotas />
);
