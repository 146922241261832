import { useState, useEffect } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { sliderData } from "./SliderData";

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideLength = sliderData.length;
    const autoScroll = true;
    const intervalTime = 5000;
    let slideInterval;

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slideLength);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slideLength) % slideLength);
    };

    const auto = () => {
        slideInterval = setInterval(nextSlide, intervalTime);
    };

    useEffect(() => {
        setCurrentSlide(0);
    }, []);

    useEffect(() => {
        if (autoScroll) {
            auto();
        }
        return () => clearInterval(slideInterval);
    }, [currentSlide]);

    const styleArrow = "border-2 border-solid border-white top-[50%] mobile:w-[1.5rem] mobile:h-[1.5rem] rounded-full text-white w-[2rem] h-[2rem] cursor-pointer bg-transparent absolute z-[5] hover:bg-white hover:text-red-400 hover:border-red-400";
    const left = " right-[1.5rem]";
    const right = " left-[1.5rem]";

    return (
        <div className="w-[100%] h-[90vh] relative overflow-hidden mobile:h-[44vh]">
            <AiOutlineArrowLeft className={styleArrow + right} onClick={prevSlide} />
            <AiOutlineArrowRight className={styleArrow + left} onClick={nextSlide} />
            {sliderData.map((item, index) => (
                <div
                    key={index}
                    className={`absolute top-0 left-0 w-full h-full transform transition-transform duration-1000 ease-in-out ${index === currentSlide ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
                    }`}
                >
                    <img
                        src={item.imagem}
                        alt={item.alt}
                        className="w-full h-full object-cover"
                    />
                </div>
            ))}
        </div>
    );
};

export default Slider;
