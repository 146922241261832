import React from "react";

export default function CardMember({item, size}){
    const sizeFotosPastores = "w-[100%] h-[94%]";
    const sizeFotosDiretores = "w-[100%] h-[246px]";

    const sizePastores = "box-border drop-shadow-lg bg-white flex flex-col w-[300px] h-[350px] rounded-lg p-4 gap-2 mobile:w-[230px] mobile:h-[300px] mobile:p-[10px] tablet-2:h-[380px] desktop:h-[400px]";
    const sizeDiretores = "box-border drop-shadow-lg bg-white flex flex-col w-[300px] h-[350px] rounded-lg p-4 gap-2 mobile:w-[230px] mobile:h-[300px] mobile:p-[10px] tablet-2:h-[380px] desktop:h-[350px] desktop:w-[280px] ";

    

    return (
        <div key={item.id} className={size === true ? sizeDiretores : sizePastores}>
            <div className={size === true ? sizeFotosDiretores : sizeFotosPastores}>
                <img className="w-[100%] h-[100%] rounded-md" src={item.imagem} alt="Imagem de um dos membros da diretoria da igreja" />
            </div>
            <div className="flex flex-row justify-between">
                <div className="flex flex-col justify-start tablet-2:pb-4 mobile:pb-4">
                    <span className="font-semibold">{item.nome}</span>
                    <span>{item.cargo}</span>
                </div>
            </div>
        </div>
    )
}