import React from "react";
import { BiRightArrow } from 'react-icons/bi';

export default function Ministerios (){

    const style = {
        list: 'flex flex-row items-center gap-2',
        icon: 'text-[12px]'
    }

    return(
        <section id="Ministerios" className="flex flex-col justify-center items-center gap-2 w-[100%] h-[400px] mobile:h-[300px] mt-20 mb-10 bg-baseMinisterio bg-cover">
            <h4 className="uppercase text-lg font-bold text-white mobile:text-2sm">Ministérios</h4>
            <ul className="font-normal text-white text-[1.5rem] mobile:text-[1rem]">
                <li className={style.list}><BiRightArrow className={style.icon}/> Acolhimento e Integração</li>
                <li className={style.list}><BiRightArrow className={style.icon}/> Comunicação</li>
                <li className={style.list}><BiRightArrow className={style.icon}/> Educação e Formação</li>
                <li className={style.list}><BiRightArrow className={style.icon}/> Ministério Infantil</li>
                <li className={style.list}><BiRightArrow className={style.icon}/> Louvor</li>
                <li className={style.list}><BiRightArrow className={style.icon}/> Diaconia</li>
            </ul>
        </section>
    )
}