import React from "react";
// import imagemAgenda from "../../assets/img/agenda/currentMouth.jpeg";
import { DadosAgenda } from "./DadosAgenda";


const Agenda = () => {

    return (
        <div className="flex flex-row mobile:flex-col mobile:gap-4">
            {DadosAgenda.map((item) => (
                <div key={item.id} className="flex justify-center">
                    <img src={item.imagem} alt={item.alt} className="w-[60%] rounded-lg shadow-lg mobile:w-[70%]" />
                </div>
            ))}
        </div>
    )
}

export default Agenda;