import React from "react";
import PDF from "../ComponentPDF/ComponentPDF";
// import carta from "../../assets/docs/carta.pdf"

export default function Estatuto(){
    var require = "../../assets/docs/Estatuto-Semente.pdf";

    return(
        <div className="flex flex-col justify-center items-center">
            <h3 className="uppercase text-[22px] font-semibold mt-8 text-center text-colorBase mobile:text-sm">
                Nosso Estatuto 
            </h3>
            
            <div className="flex flex-col items-center justify-center">
                <a className="flex flex-col items-center pt-2 pr-2 pl-2 pb-2 text-center text-colorBase hover:text-red-600 font-semibold duration-700 uppercase tracking-widest rounded-lg mt-2 mobile:py-1 mobile:px-2" href={require} download>
                    <PDF className="w-[25%] h-[30%] p-4 mt-4 mobile:w-[40%]"/>
                    Dowload
                </a>
            </div>
        </div>
    );
}