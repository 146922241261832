export const sliderData = [
    {
        "id": 1,
        "alt": "Imagem da nossa semente",
        "imagem": "./assets/img/carrossel/semente6.png"
    },
    {
        "id": 2,
        "alt": "Imagem da nossa semente",
        "imagem": "./assets/img/carrossel/semente2.png"
    },
    {
        "id": 3,
        "alt": "Imagem da nossa semente",
        "imagem": "./assets/img/carrossel/semente3.png"
    },
    {
        "id": 4,
        "alt": "Imagem da nossa semente",
        "imagem": "./assets/img/carrossel/semente4.png"
    },
    {
        "id": 5,
        "alt": "Imagem da nossa semente",
        "imagem": "./assets/img/carrossel/semente5.png"
    },
    {
        "id": 6,
        "alt": "Imagem da nossa semente",
        "imagem": "./assets/img/carrossel/semente1.png"
    }
]